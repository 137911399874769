import { api, createCrudService } from '@cargill/shared'

const service = createCrudService('/api/material', api)

service.send = async () => await api.get('/api/material/send')

service.receive = async () => await api.get('/api/material/receive')

window.materialService = service

export default service
